@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
body {
  margin: 0;
  font-family: 'Inter', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html{
  scroll-behavior: smooth;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.inset-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.one {
  /* The image used */
  background-image: url("./images/art1.png");
  height: 266px; 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.two {
  /* The image used */
  background-image: url("./images/art2.png");
  height: 266px; 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.three {
  /* The image used */
  background-image: url("./images/art3.png");
  height: 266px; 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.four {
  /* The image used */
  background-image: url("./images/art4.png");
  height: 266px; 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.five {
  /* The image used */
  background-image: url("./images/art5.png");
  height: 266px; 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.six {
  /* The image used */
  background-image: url("./images/mural.png");
  height: 266px; 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.seven {
  /* The image used */
  background-image: url("./images/art7.png");
  height: 266px; 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.eight {
  /* The image used */
  background-image: url("./images/art8.png");
  height: 266px; 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.facee {
  /* The image used */
  background-image: url("./images/facee.png");
  height: 122px; 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.dd {
  /* The image used */
  background-image: url("./images/d1.png");
  height: 266px; 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.slider-container {
    --progress-bar-height: 4px;
  }
  
  .slider-container {
    position: relative;
    width: 100%;
  }
  
  /* Background */
  .slider-container::before {
    content: '';
    background-color: #B0B0B0;
    width: 99%;
    height: calc(var(--progress-bar-height) - 1px);
    display: block;
    position: absolute;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }
  
  /* Custom Progress Bar */
  .progress-bar-cover {
    background-color: #7210EE;
    width: 0%;
    height: var(--progress-bar-height);
    display: block;
    position: absolute;
    border-radius: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    user-select: none;
    pointer-events: none;
  }
  
  /*  Hide Original */
  .range {
    -webkit-appearance: none;
    background-color: rgba(240, 9, 9, 0.397);
    height: 10px;
    width: 100%;
    cursor: pointer;
    opacity: 0;
    margin: 0 auto;
  }
  